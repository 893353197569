<template>
    <div class="search-box">
        <div class="header">
            <input type="text" :placeholder="input.placeholder" @keyup.enter="handleSearch" v-model="input.value" ref="input" @blur="blur" @focus="focus">
            <img src="../../assets/images/search.png" alt="" width="17" height="17" class="searchImg">
            <div @click="goBack">取消</div>
        </div>
        <div class="card">
            <div class="title">搜索发现</div>
            <div class="wrap">
                <div class="panel" v-for="(list, index) in lists" :key="index" @click="handleClick(list.id)">
                    <div class="span">
                        <img src="../../assets/images/hot-fill.png" alt="" width="25" height="25">
                        <span>{{ list.text }}</span>
                    </div>
                    <img src="../../assets/images/enter.png" alt="" width="20" height="20">
                </div>
            </div>
        </div>
        <div class="beian" v-if="showBeian">
            <img src="../../assets/images/beian.png" alt="" width="22" height="22">
            <a href="http://beian.miit.gov.cn/">{{ beianId }}</a>
        </div>
        <logout ref="logout" @resetInputVal="resetInputVal"/>
        <login-dialog ref="login"/>
    </div>
</template>

<script>
const seo = require('../../../config/index')
import Logout from '../../components/dialog/Logout.vue'
import LoginDialog from '../../components/dialog/LoginDialog.vue'
undefined
export default {
  name: 'Search',
  components: {
    Logout,
    LoginDialog
  },
  created(){
    this.$bus.$on('resetInputVal',this.resetInputVal)
  },
  mounted(){
    this.input.value = this.$route.query.input ? this.$route.query.input : ''
  },
  activated(){
        // 自动获取焦点
        setTimeout(()=>{
            this.$refs.input.focus()
            this.$refs.logout.isShow = false
            this.$refs.login.isShow = false
            this.isLogin = localStorage.getItem('token') ? true : false
        }, 0)
  },
  data:()=>{
    return {
        lists: seo.config.searchLists,
        input:{
            placeholder: '搜索你想要的内容',
            value:''
        },
        isLogin: localStorage.getItem('token') ? true : false,
        showBeian: false,
        beianId: seo.config.beianId
    }
  },
  methods:{
        handleSearch(){
            let inputVal = this.input.value.trim()
            let type = this.$route.query.type ? this.$route.query.type : 'article'
            let tabId = type === 'article' ? '0' : '1'
            if(inputVal){
                if(inputVal.includes('退出登录') || inputVal.includes('logout') 
                        || inputVal.includes('登出') || inputVal.includes('取消登录')){
                    this.$refs.logout.show()
                } else if(inputVal.includes('个人中心') || inputVal.includes('个人主页')){
                    if(this.isLogin){
                        this.$router.push('/personal')
                    } else {
                        this.$router.push('/login')
                        this.$bus.$emit('resetInputVal')
                    }
                } else {
                    sessionStorage.setItem('tabId', tabId)
                    this.$router.push({ path: '/search-result', query: { input: inputVal, type: type }})
                }
            }
        },
        handleClick(id){
            // 只要点了就清空
            this.input.value = ''
            if(id === 'personal'){
                if(this.isLogin){
                        this.$router.push('/personal')
                } else {
                    this.$refs.login.show()
                    this.$bus.$emit('resetInputVal')
                }
            } else if(id === 'logout'){
                this.$refs.logout.show()
            } else if(id === 'createArticle'){
                if(this.isLogin){
                        this.$router.push('/create')
                } else {
                    this.$refs.login.show()
                    this.$bus.$emit('resetInputVal')
                }
            } else if(id === 'vision'){
                this.$router.push({ path:'/about' })
            } else if(id === 'draftBox'){
                if(this.isLogin){
                    this.$router.push({ path:'/draft-box' })
                } else {
                    this.$refs.login.show()
                    this.$bus.$emit('resetInputVal')
                }
            } else if(id === 'booking'){
                this.$router.push({ path:'/booking' })
            } else if(id === 'download'){
                this.$router.push({ path:'/download' })
            } else if(id === 'shop'){
                this.$router.push({ path:'/shop' })
            } else if(id === 'chatRobot'){
                this.$router.push({ path:'/chat' })
            } else if(id === 'activesvip'){
                if(this.isLogin){
                    this.$router.push({ path:'/activesvip' })
                } else {
                    this.$refs.login.show()
                    this.$bus.$emit('resetInputVal')
                }
            } else if(id === 'coupon'){
                if(this.isLogin){
                    this.$router.push({ path:'/coupon' })
                } else {
                    this.$refs.login.show()
                    this.$bus.$emit('resetInputVal')
                }
            }
        },
        goBack(){
            this.input.value = ''
            sessionStorage.removeItem('tabId')
            if(sessionStorage.getItem('fromPath') === 'shop'){
                this.$router.push('/shop')
                sessionStorage.removeItem('fromPath')
            } else if(sessionStorage.getItem('fromPath') === 'category'){
                this.$router.push('/category')
                sessionStorage.removeItem('fromPath')
            } else {
                this.$router.push('/')
            }
            // this.$router.back()
        },
        resetInputVal(){
            this.input.value = ''
        },
        blur(){
            setTimeout(()=>{
                this.showBeian = true
            },100)
        },
        focus(){
            // 判断打开的设备是否是移动端
            if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)){
                this.showBeian = false // 移动端
            } else {
                this.showBeian = true // PC端
            }
        }
  },
}
</script>

<style scoped lang="scss">
.search-box {
    position: relative;
    height: 100vh;
    background: #f3f3f3;
    .header {
        display: flex;
        align-items: center;
        input {
            width: 100%;
            border-radius: 20px;
            height: 40px;
            border: 1px solid #eee;
            margin: 10px 5px 0 10px;
            padding-left: 40px;
            box-sizing: border-box;
            -webkit-appearance: none;
        }
        div {
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            margin-top: 10px;
            width: 40px;
            min-width: 48px;
            padding-right: 10px;
        }
        .searchImg {
            position: absolute;
            left: 25px;
            top: 21px;
        }
    }
    .card {
        text-align: left;
        margin: 40px 20px 20px;
        .title {
            font-weight: 600;
            font-size: 16px;
        }
        .wrap {
            background: #fff;
            margin: 10px 0;
            .panel {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 10px;
                border: 1px solid #f3f3f3;
                .span {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
    .beian {
        position: fixed;
        bottom: 20px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        img {
            margin-right: 5px;
        }
        a {
            color: #2c3e50;
        }
    }
}

</style>