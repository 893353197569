<template>
    <div v-show="isShow">
        <div class="login-dialog-box" @click="login">
            <span>您还未登录,去登陆？</span>
        </div>
        <div class="shadow" @click="show"></div>
    </div>
</template>

<script>
export default {
  name: 'LoginDialog',
  components: {
  },
  activated() {
    if(localStorage.getItem('token')){
        this.isShow = false
    }
  },
  data(){
      return {
          isShow: false,
      }
  },
  methods:{
        show(){
            this.isShow = !this.isShow
        },
        login(){
            this.$router.push({ path:'/login' })
        },
  },
  props:{

  }
}
</script>

<style scoped lang="scss">
.shadow {
    position: fixed;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.3);
}
.login-dialog-box {
    width: 80%;
    height: 50px;
    line-height: 50px;
    margin: 0 auto;
    max-width: 625px;
    left: 0;
    right: 0;
    color: #000;
    background: #FFF;
    text-align: center;
    font-size: 20px;
    position: fixed;
    top: 50%;
    z-index: 999;
}
</style>
